import React from 'react';
import { createRoot } from 'react-dom/client';
import { hydrateRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';

const pages = import.meta.glob('../pages/**/*.jsx');
const ssrEnabled = import.meta.env.VITE_RUBY_SSR_BUILD_ENABLED === 'true';

createInertiaApp({
  progress: {
    color: '#15803d',
    delay: 250,
    showSpinner: false
  },
  resolve: async name => {
    const page = (await pages[`../pages/${name}.jsx`]()).default;
    return page;
  },
  setup({ el, App, props }) {
    if (ssrEnabled) {
      hydrateRoot(el, <App {...props} />);
    } else {
      createRoot(el).render(<App {...props} />);
    }
  },
});
